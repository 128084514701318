import Swiper, { Navigation } from 'swiper';

Swiper.use([Navigation]);

document.addEventListener('DOMContentLoaded', () => {
  let partnersSlider = null;
  const partners = document.querySelector('.partners__wrap');
  if (partners) {
        partnersSlider = new Swiper('.partners__wrap', {
          navigation: {
            nextEl: '.partners__wrap .swiper-button-next',
            prevEl: '.partners__wrap .swiper-button-prev',
          },
          draggable: true,
          centeredSlides: false,
          spaceBetween: 20,
          grabCursor: true,
          init: true,
          loop: true,
          loopFillGroupWithBlank: true,
          watchSlidesProgress: true,
          watchSlidesVisibility: true,
          slidesPerView: 1,
          slidesPerGroup: 1,
          freeMode: true,
          freeModeMomentumBounce: false,
          freeModeSticky: false,
          breakpoints: {
              320: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 35,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              1366: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              1600: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
              1920: {
                slidesPerView: 5,
                spaceBetween: 50,
              }
          },
        });
        partnersSlider && partnersSlider.init();
    }
})



