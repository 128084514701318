import React from "react";
import '../../css/form/modal.scss'

const Modal = ({title, children, onBackgroundClick, onCloseClick}) => {


  return (
    <div className={'modal'}>
      <div className={`modal-background ${onBackgroundClick ? 'clickable' : ''}`} onClick={onBackgroundClick}/>
      <div className="modal-body">
        <div className="modal-title">
          {title}
        </div>
        {onCloseClick && <button className="modal-close" onClick={onCloseClick}/>}
        <div className="modal-content">
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal
